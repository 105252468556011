<template>
  <page-main back title class="recovery-station">
    <div class="search-wrapper">
      <!-- form 表单栏 -->
      <el-form inline class="form">
        <el-form-item>
          <el-input
            v-model="search.title"
            v-debounce="[onSearch, 'input']"
            class="input"
            clearable
            prefix-icon="el-icon-search"
            placeholder="请输入关键字"
            @clear="onSearch"
            @keydown.enter.native="onSearch"
          />
        </el-form-item>
        <el-form-item>
          <ksh-select
            v-model="search.dataType"
            class="select"
            clearable
            placeholder="请选择类型"
            @change="onSearch"
          >
            <el-option
              v-for="item in recoveryType"
              :key="item.intValue"
              :label="item.stringValue"
              :value="item.intValue"
            />
          </ksh-select>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      stripe
      style="border: 1px solid #f4f1f5; border-bottom: 0;"
    >
      <el-table-column prop="title" label="标题/题目名称" fixed>
        <template slot-scope="scoped">
          {{ scoped.row.title | titleFilter }}
        </template>
      </el-table-column>
      <el-table-column prop="type" width="200px" label="类型" />
      <el-table-column
        width="200px"
        align="center"
        prop="trashTime"
        label="删除时间"
      />
      <el-table-column
        align="center"
        prop="operateName"
        label="删除组织人员"
      />
      <el-table-column width="150px" align="center" label="操作">
        <template slot-scope="scoped">
          <el-button
            :disabled="!scoped.row.isCanRestore"
            type="text"
            @click="recover(scoped.row)"
          >
            恢复
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.sizes"
      :layout="pagination.layout"
      :small="pagination.small"
      :hide-on-single-page="false"
      class="pagination"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
    <ProductLabel
      :visible.sync="isShowTagModal"
      :disabled-ids="[]"
      :selected-tag-list="[]"
      :options="{
        'SPREAD_DEPARTMENT_GROUP': {
          isShowTab: false
        },
        'CUSTOMIZE_TAG_GROUP': {
          isShowTab: false
        }
      }"
      @onSubmit="onSubmitTag"
    />
  </page-main>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import ProductLabel from '@/bizComponents/ProductLabel'
export default {
  name: 'RecoveryStation',
  filters: {
    titleFilter(title) {
      const titleNew = title?.replace(/<filter><\/filter>/g, '__ ')?.replace(/,/g, '，')
      return titleNew
    }
  },
  components: {
    ProductLabel
  },
  mixins: [paginationMixin],
  data() {
    return {
      dialog: false,
      search: {
        title: '',
        dataType: '',
        isQueryChild: false
      },
      optionsList: [],
      recoveryType: [],
      tableData: [],
      orgSource: [
        {isQueryChild: false, title: '我创建的素材'},
        {isQueryChild: true, title: '下级组织素材'}
      ],
      isShowTagModal: false,
      currentRow: {},
      userTagType: ''
    }
  },
  mounted() {
    this.getDataList()

    this.getRecoveryType()
  },
  methods: {
    // 获取列表数据
    async getDataList() {
      this.loading = true
      try {
        const params = this.getParams(this.search)
        const {
          data: { data, totalCount }
        } = await this.$axios.get(this.$API.trashList, { params })
        this.tableData = data
        this.pagination.total = totalCount
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 恢复删除的某条数据
    async recover(row) {
      this.$MessageDialog({
        title: '提示',
        message: '<div style="text-align: center; margin: 20px;">确认恢复这条数据吗？</div>'
      }).then(() => {
        if (row.isCanSetProductTag) {
          this.onValid(row)
        } else {
          this.onRestore(row)
        }
      }
      )
    },
    onValid(row) {
      this.$MessageDialog({
        title: '提示',
        message: '<div style="text-align: center; margin: 20px;">恢复前请先设置产品标签</div>'
      }).then(() => {
        this.currentRow = row
        this.userTagType = row.type === '题目' ? '15' : row.type === '视频' ? '10' : row.type === '文献' ? '14' : ''
        this.isShowTagModal = true
      })
    },
    async onRestore(row) {
      try {
        const params = {
          id: row.id
        }
        await this.$axios.get(this.$API.trashRecall, {params})
        this.getDataList()
        this.$message.success('恢复成功')
      } catch (error) {
        console.log(error)
        this.$message.error(error.message)
      }
    },
    // 获取回收站数据类型
    async getRecoveryType() {
      try {
        const {data} = await this.$axios.get(this.$API.queryType)
        this.recoveryType = data
      } catch (error) {
        console.log(error)
      }
    },
    // 设置产品标签
    onSubmitTag(newSelectList) {
      this.isShowTagModal = false
      const memberTagInfo = newSelectList.map(v => {
        return {
          userTagType: this.userTagType,
          tagId: v.tagId,
          groupId: v.groupId,
          tagName: v.tagName,
          knowledgePointIds: v.knowledgePointIds
        }
      })
      this.onSetTag(memberTagInfo, this.currentRow)
    },
    onSetTag(memberTagInfo, row) {
      const data = {
        itemIds: [row.dataId],
        memberTagInfo
      }
      this.$axios.post(this.$API.batchSetTag, data).then(() => {
        this.onRestore(row)
      }, rea => this.$message.error(rea.message))
    },
    // 搜索
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.recovery-station {
  .input {
    width: 160px;
    ::v-deep .el-input__inner {
      border-radius: 20px;
    }
  }
  .select {
    width: 120px;
  }
  .pagination {
    margin-top: 20px;
  }
}
</style>
